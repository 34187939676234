import React, { useEffect, useRef } from 'react';
import './ParallaxAbout.css';
import ProjectBlock from './ProjectBlock';

const ParallaxAbout = ({ projects }) => {
    const sectionRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
            rootMargin: '0px 0px -100px 0px'
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        const currentRefs = sectionRefs.current;
        currentRefs.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            currentRefs.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <div className="parallax-container">
            {projects.map((project, index) => (
                <section 
                    key={index}
                    className="parallax-section"
                >
                    <div 
                        className="project-section"
                        ref={el => sectionRefs.current[index] = el}
                    >
                        <div className="section-content">
                            <ProjectBlock {...project} />
                        </div>
                    </div>
                </section>
            ))}
        </div>
    );
};

export default ParallaxAbout;